
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import StoreView from "./store-view.vue";
import StoreEdit from "./store-edit.vue";
import { mobileRulesEl } from "@/utils/verify";
import AreaSelect from "@/components/scope/area-select.vue";
import { generalNumberRules } from "@/utils/verify";
import t from "@common/src/i18n/t";

const app = namespace("app");
const management = namespace("management");
const setting = namespace("setting");

@Component({
  components: { StoreView, AreaSelect, StoreEdit }
})
export default class StoreOpen extends Vue {
  @app.Mutation setPBPX;
  @management.Action getApplyInfo;
  @management.Action getTenantList;
  @management.Action createStoreByTmall;
  @management.Action getTenantDetail;
  @management.Action queryStoreCode;
  @setting.Action getFunctionList;
  @management.Action createStoreNotGiti;
  showAreaSelect: boolean = false;
  showPickingTips: boolean = false;
  tenantFormData: any = {
    tenantFromType: "1", // 1新建 2现有
    gitiFlag: "0",
    tenantCode: "",
    tenantName: "",
    tenantContactsFirstName: "",
    tenantContactsPhone: "",
    tenantProvince: "",
    tenantCity: "",
    tenantDistrict: "",
    area: null,
    tenantAddress: "",
    appType: "1",
    applyCode: ""
  };
  tenantFormRules: any = {
    tenantCode: {
      required: true,
      message: t("v210831.please-select-tenant-lessee-detail"),
      trigger: "change"
    },
    tenantName: {
      required: true,
      message: t("v210831.please-enter-tenant-lessee-name"),
      trigger: "blur"
    },
    tenantContactsFirstName: {
      required: true,
      message: t("v210831.please-enter-contact-linkman"),
      trigger: "blur"
    },
    tenantContactsPhone: [
      { required: true, message: t("v210831.please-enter-thelinkman-mobile"), trigger: "blur" },
      { validator: this.validateMobile, trigger: "blur" }
    ]
  };
  get storeNumberFormRules() {
    return {
      storeCode: [
        {
          required: false,
          message: t("v210831.please-enter-the-store-number"),
          trigger: "blur"
        }
        // { validator: this.checkStoreCode, trigger: "blur" }
      ],
      externalStoreCode: [
        {
          required: false,
          message: t("v210831.please-enter-external-output-number"),
          trigger: "blur"
        },
        { validator: this.checkExternalStoreCode, trigger: "blur" }
      ]
    };
  }
  get isGiti() {
    // return this.$route.query.type !== "notGiti";
    return false;
  }
  validateMobile(rule, value, callback) {
    if (!value) {
      callback();
    } else {
      mobileRulesEl(value, callback);
    }
  }
  storeNumberFormData: any = {
    storeCode: "",
    externalStoreCode: ""
  };
  treeProps = {
    label: "menuName"
  };
  storeData: any = {};
  pcPermList: any = [];
  appPermList: any = [];
  standardPcPermList: any = [];
  standardAppPermList: any = [];
  stepNum: number = 1;
  created() {
    this.setPBPX(52);
    this.init();
  }
  mounted() {
    this.getPermission("1");
    this.getPermission("2");
  }
  checkStoreCode(rule, value, callback) {
    if (!generalNumberRules(value).success) {
      return callback(new Error(t("v210831.the-store-code-underline-number")));
    }
    this.queryStoreCode({
      storeCode: value
    }).then(
      data => {
        callback();
      },
      data => {
        if (data.code === "speedbiz.system.exist-store-code") {
          callback(new Error(t("v210831.the-store-code-already-have")));
        } else {
          callback();
        }
      }
    );
  }
  checkExternalStoreCode(rule, value, callback) {
    if (!value) {
      return callback();
    }
    if (!generalNumberRules(value).success) {
      return callback(new Error(t("v210831.the-store-external-output-underline-number")));
    }
    return callback();
  }
  get applyCode() {
    return this.$route.query.code;
  }
  get composeTenantList() {
    return this.tenantList;
  }
  tenantList: any = [];
  handleTenantTypeChange() {
    this.tenantFormData.tenantName = "";
    this.tenantFormData.tenantCode = "";
    this.tenantFormData.tenantContactsFirstName = "";
    this.tenantFormData.tenantContactsPhone = "";
    this.tenantFormData.area = null;
    this.tenantFormData.tenantAddress = "";
  }
  getTenantListData(value) {
    this.getTenantList({
      tenantName: value,
      pageNum: 1,
      pageSize: 20
    }).then(data => {
      this.tenantList = data.data.list;
    });
  }
  searchTenant(val) {
    this.getTenantListData(val);
  }
  originalName: string = "";
  originalPhone: string = "";
  init() {
    this.getTenantListData("");
    if (this.applyCode) {
      this.getApplyInfo({
        applyCode: this.applyCode
      }).then(data => {
        this.tenantFormData.tenantContactsFirstName = data.data.contactsName;
        this.tenantFormData.tenantContactsPhone = data.data.contactsPhone;
        this.originalName = data.data.contactsName;
        this.originalPhone = data.data.contactsPhone;
        // this.tenantFormData.tenantProvince = data.data.province;
        // this.tenantFormData.tenantCity = data.data.city;
        // this.tenantFormData.tenantDistrict = data.data.district;
        // this.tenantFormData.tenantAddress = data.data.address;
        this.storeData = data.data;
        this.$nextTick(() => {
          this.tenantFormData.tenantCode = data.data.tenantCode || data.data.k2Name;
          this.tenantFormData.tenantName = data.data.tenantName || data.data.k2Name;
        });
        this.showAreaSelect = true;
      });
    } else {
      this.showAreaSelect = true;
    }
  }
  get breadData() {
    return [{ name: "门店管理", path: "/management/store-list" }, { name: t("v210831.open-store-account") }];
  }
  async getPermission(type) {
    let permission = await this.getFunctionList({
      appType: type
    });
    if (type === "1") {
      this.pcPermList = this.viewFilterTree(permission.data.pcMenuList);
      this.appPermList = this.viewFilterTree(permission.data.appMenuList);
      // (this.$refs["pcTree"] as any).setCheckedKeys(this.checkKeys, false);
      // (this.$refs["appTree"] as any).setCheckedKeys(this.checkKeys, false);
    } else {
      this.standardPcPermList = this.viewFilterTree(permission.data.pcMenuList);
      this.standardAppPermList = this.viewFilterTree(permission.data.appMenuList);
    }
  }
  handleAreaChange(val) {
    this.$nextTick(() => {
      this.$nextTick(() => {
        const labels = (this.$refs["areaCascader"] as any).getLabels();
        this.tenantFormData.tenantProvince = labels[0];
        this.tenantFormData.tenantCity = labels[1];
        this.tenantFormData.tenantDistrict = labels[2];
      });
    });
  }

  checkKeys: any = [];
  viewFilterTree(orginalTree) {
    const tree = orginalTree;
    let _this = this;
    let f = function(treeArr) {
      for (let index = 0; index < treeArr.length; index++) {
        const element = treeArr[index];
        _this.checkKeys.push(element.resourceCode);
        element.disabled = true;
        if (element.children) {
          f(element.children);
        }
      }
    };
    f(tree);
    return tree;
  }
  handleChooseTenant(val) {
    this.getTenantDetail({
      tenantCode: val
    }).then(data => {
      if (!data.data) {
        this.tenantFormData.tenantContactsFirstName = this.originalName;
        this.tenantFormData.tenantContactsPhone = this.originalPhone;
        this.tenantFormData.tenantName = this.tenantFormData.tenantCode;
        this.tenantFormData.area = [];
        this.tenantFormData.tenantProvince = "";
        this.tenantFormData.tenantCity = "";
        this.tenantFormData.tenantDistrict = "";
        this.tenantFormData.tenantAddress = "";
        return;
      }
      this.tenantFormData.tenantContactsFirstName = data.data.contactName;
      this.tenantFormData.tenantContactsPhone = data.data.mobile;
      this.tenantFormData.area = [data.data.stateName, data.data.cityName, data.data.districtName];
      this.tenantFormData.tenantProvince = data.data.stateName;
      this.tenantFormData.tenantCity = data.data.cityName;
      this.tenantFormData.tenantDistrict = data.data.districtName;
      this.tenantFormData.appType = data.data.appType;
      this.showAreaSelect = false;
      this.$nextTick(() => {
        this.showAreaSelect = true;
      });
      this.tenantFormData.tenantAddress = data.data.address1;
    });
  }
  storeCodeDisable: boolean = false;
  handleNextStep() {
    if (this.stepNum === 1) {
      (this.$refs["refStep1Form"] as any).validate(valid => {
        valid && this.stepNum < 3 && this.stepNum++;
      });
    } else {
      this.stepNum < 3 && this.stepNum++;
      if (this.stepNum === 3) {
        // 第三步，设置默认门店编号
        !this.storeNumberFormData.storeCode && (this.storeNumberFormData.storeCode = this.storeData.k2Number);
        this.storeNumberFormData.storeCode && (this.storeCodeDisable = true);
        this.storeNumberFormData.externalStoreCode = this.storeData.k2Number;
      }
    }
  }
  handlePrevStep() {
    this.stepNum > 1 && this.stepNum--;
  }

  handleSubmit(flag) {
    if (!this.isGiti) {
      this.openStoreNotGiti();
      return;
    }
    if (this.storeNumberFormData.externalStoreCode !== this.storeNumberFormData.storeCode && !flag) {
      this.showPickingTips = true;
      return;
    }
    (this.$refs.refStep3Form as any).validate((valid, obj) => {
      if (valid) {
        (this.$refs["btnSave"] as any).startLoading();
        this.createStoreByTmall(
          Object.assign({}, this.tenantFormData, this.storeNumberFormData, {
            applyCode: this.applyCode
          })
        )
          .then(data => {
            this.$router.push(`/management/store-create-suc`);
          })
          .finally(() => {
            (this.$refs["btnSave"] as any).stopLoading();
          });
      } else {
        Object.keys(obj)[0] && this.$message.error(obj[Object.keys(obj)[0]][0].message);
      }
    });
  }
  openStoreNotGiti() {
    const storeData = (this.$refs.storeForm as any).getStoreData();
    if (!storeData) return; // 格式效验没通过
    let params = Object.assign({}, storeData, this.tenantFormData);
    this.createStoreNotGiti(params).then(data => {
      this.$message.success(t("base.create-success"));
      this.$router.push("/management/store-list");
    });
  }
}
